.header-title {
    color: black;
    font-size: 32px;
    font-weight: 500;
    text-decoration: none;
    margin-right: 20px;
}

header {
    display: flex;
    align-items: center;
    border: 1px;
    margin-bottom: 20px;
}
  