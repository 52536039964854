button {
    color: black;
    background-color: transparent;
    font-size: 20px;
    border: 1px solid gray;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 8px;
}

button:hover {
    color: white;
    background-color: black;
    cursor: pointer;
}